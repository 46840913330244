import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import './login.css';
import axios from 'axios';
import {v4 as uuidv4} from "uuid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [selectedMode, setSelectedMode] = useState('development mode');
    const [error, setError] = useState('');
    const [csrfToken, setCsrfToken] = useState(null);
    const navigate = useNavigate();  // hook for programmatic navigation

    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');


    useEffect(() => {
        axios.get('/api/get-csrf-token/')
            .then(response => {
                setCsrfToken(response.data.csrfToken);
            });
    }, []);


    const handleSubmit = (event) => {
        event.preventDefault();
        const job_id = uuidv4();
        axios.defaults.headers.post['X-CSRFToken'] = csrfToken;

        axios.post(
            "/api/login/",
            {
                parameters: {username: username, password: password, start_datetime: new Date(), mode: selectedMode},
                request_name: 'login',
                job_id: job_id
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            })
            .then(response => {
                if (response.data.status === "success") {
                    const data_info = response.data.response_data;
                    const storageKey = data_info.user.storage_key;
                    const userName = data_info.user.first_name;
                    const storageSpace = data_info.storage_space;
                    const accountStatus = data_info.user.status;
                    const userId = data_info.user.account_id;

                    if (accountStatus === 0) {
                        showErrorDialog('The account have been disabled.');
                        return;
                    }

                    // Based on the selected mode, navigate to the appropriate page with the relevant data
                    if (selectedMode === 'project_selection' || selectedMode === 'project_management') {
                        // Fetch project data and navigate to the project page
                        axios.post(
                            `/api/project/${storageKey}/`,
                            {
                                parameters: {
                                    folder_name: 'project',
                                    encrypted_id: storageKey
                                },
                                request_name: 'enter_project',
                                job_id: uuidv4()
                            },
                            {
                                headers: {'X-CSRFToken': csrfToken},
                                withCredentials: true
                            }
                        ).then(projectResponse => {
                            if (projectResponse.data.status === "success") {
                                const projectData = projectResponse.data.response_data;
                                const userDataForProject = {
                                    page_mode: selectedMode,
                                    user_name: userName,
                                    user_id: userId,
                                    storage_key: storageKey,
                                    storage_space: storageSpace,
                                    storageData: projectData.storage_json,
                                    current_path: storageKey + '/' + 'projects' + '/',
                                    current_fold_name: 'projects'
                                };
                                navigate(`/project-manager/${storageKey}`, {state: userDataForProject});
                            } else {
                                console.error("Error in project selection:\n", projectResponse.data.response_data.error_message);
                            }
                        }).catch(error => {
                            console.error("Error fetching project data:", error);
                        });
                    } else {
                        // Use the login response data for storage or other modes and navigate accordingly
                        const userDataForStorage = {
                            page_mode: selectedMode,
                            user_name: userName,
                            user_id: userId,
                            storage_key: storageKey,
                            storage_space: storageSpace,
                            storageData: data_info.storage_json,
                            current_path: 'space_test_version/' + storageKey + '/' + 'useruploads' + '/',
                            current_fold_name: 'useruploads'
                        };
                        navigate(`/storage/${storageKey}`, {state: userDataForStorage});
                    }
                } else if (response.data.status === "invalid_credentials") {
                    showErrorDialog("The input email and password do not match our record. Please try again.")
                } else {
                    console.error("Error login:\n", response.data.response_data.error_message);
                }
            })
            .catch(error => {
                console.error("Error logging in:", error);
            });
    };

    const showErrorDialog = (message) => {
        setErrorMessage(message);
        setIsErrorDialogOpen(true);
    };

    const closeErrorDialog = () => {
        setIsErrorDialogOpen(false);
    };


    return (<div className="container">
        <Dialog open={isErrorDialogOpen} onClose={closeErrorDialog} onClick={(event) => event.stopPropagation()}>
            <DialogTitle>Alert</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {errorMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeErrorDialog} color="primary">
                    Okay
                </Button>
            </DialogActions>
        </Dialog>
        <h2>Login Page</h2>
        <form onSubmit={handleSubmit}>
            <div className="inputField">
                <label htmlFor="username">Username:</label>
                <input
                    type="text"
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                />
            </div>
            <div className="inputField">
                <label htmlFor="password">Password:</label>
                <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
            </div>
            {error && <p className="error">{error}</p>}
            <div className="radioGroupContainer">
                <RadioGroup value={selectedMode} onChange={(e) => setSelectedMode(e.target.value)}>
                    <FormControlLabel value="development_mode" control={<Radio/>} label="Development Mode"/>
                    <FormControlLabel value="full_storage" control={<Radio/>} label="Full Storage"/>
                    <FormControlLabel value="file_management" control={<Radio/>} label="File Management"/>
                    <FormControlLabel value="project_management" control={<Radio/>} label="Project Management"/>
                    <FormControlLabel value="file_selection" control={<Radio/>} label="File Selection"/>
                    <FormControlLabel value="project_selection" control={<Radio/>} label="Project Selection"/>
                </RadioGroup>
            </div>
            <button className="button" type="submit">Log in</button>
        </form>
    </div>);
};

export default Login;
