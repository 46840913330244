import React, {useState, useEffect, useCallback} from 'react';
import Select from 'react-select';

const FIELD_TITLES = {
    Modality: "Modality",
    Program: "Program",
    CancerType: "Cancer Type",
    tcia_tumorLocation: "Primary Site Location",
    access: "Access",
    Collections: "Collections",
    collection_id: "Collections"
};

const VALUE_MAPPINGS = {
    Modality: {
        CT: "Computed Tomography",
        MR: "Magnetic Resonance",
        ANN: "Annotation",
        SEG: "Segmentation Storage",
        CR: "Computed Radiography",
        SM: "Slide Microscopy",
        PT: "Presentation State",
        OT: "Other",
        NM: 'Nuclear Medicine'

    },
    collection_id: {},
    Collections: {}
};

const containerStyle = {
    backgroundColor: '#e6f2ff',
    minHeight: '100vh',
    padding: '20px'
};

const sectionStyle = {
    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
    borderRadius: '0.5rem',
    border: '1px solid #e5e7eb',
    backgroundColor: '#f9f9f9',
    marginBottom: '2rem',
    padding: '1.5rem',
    position: 'relative',
    zIndex: 10
};

const LoadingOverlay = ({isLoading}) => {
    if (!isLoading) return null;

    return (
        <div
            className="fixed inset-0"
            style={{
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                backdropFilter: 'blur(2px)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100vw',
                height: '100vh',
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: 100
            }}
        >
            <div className="text-white text-xl font-semibold">Loading...</div>
        </div>
    );
};

const PaginatedSection = ({
                              title,
                              items = [],
                              selectedItems,
                              onItemSelect,
                              onSelectAll,
                              onSelectNone,
                              itemsPerPage = 10,
                              searchPlaceholder,
                              multiSelect = false,
                              showDownload = false,
                              isDisabled = false,
                              onDownload = null
                          }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');

    const filteredItems = items.filter(item => {
        const searchValue = typeof item === 'object' ? item.SeriesInstanceUID : item;
        return searchValue.toLowerCase().includes(searchTerm.toLowerCase());
    });

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

    const handlePageChange = (newPage) => {
        setCurrentPage(Math.min(Math.max(1, newPage), totalPages));
    };

    const isSelected = (id) => {
        const itemId = typeof id === 'object' ? id.SeriesInstanceUID : id;
        return multiSelect ? selectedItems.includes(itemId) : selectedItems === itemId;
    };

    return (
        <div className="mb-6" style={sectionStyle}>
            <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-semibold">
                    {title} ({filteredItems.length})
                </h3>
                <div className="flex gap-2">
                    <button
                        className="px-3 py-1 text-black rounded bg-gray-200 hover:bg-gray-300"
                        onClick={onSelectAll}
                        disabled={isDisabled}
                    >
                        Select All
                    </button>
                    <button
                        className="px-3 py-1 text-black rounded bg-gray-200 hover:bg-gray-300"
                        onClick={onSelectNone}
                        disabled={isDisabled}
                    >
                        Select None
                    </button>
                    {showDownload && (
                        <button
                            className={`px-4 py-2 bg-gray-600 text-black rounded ${selectedItems.length > 0 ? 'hover:bg-gray-700' : 'opacity-50 cursor-not-allowed'}`}
                            disabled={selectedItems.length === 0}
                            onClick={() => window.alert('Download functionality coming soon!')}
                        >
                            Download Selected
                        </button>
                    )}
                </div>
            </div>

            <div className="flex items-center mb-4">
                <input
                    type="text"
                    placeholder={searchPlaceholder}
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                        setCurrentPage(1);
                    }}
                    className="flex-grow p-2 border rounded"
                    disabled={isDisabled}
                />
            </div>

            <div className="max-h-60 overflow-y-auto border rounded p-2 bg-white">
                {title === "Series IDs" && (
                    <table className="w-full">
                        <thead>
                        <tr className="bg-gray-100">
                            <th className="p-2 text-left w-12"></th>
                            <th className="p-2 text-left">SeriesInstanceUID</th>
                            <th className="p-2 text-left">Modality</th>
                            <th className="p-2 text-left">Collection</th>
                            <th className="p-2 text-left">PatientID</th>
                            <th className="p-2 text-left">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currentItems.map((item) => {
                            const id = typeof item === 'object' ? item.SeriesInstanceUID : item;
                            if (title === "Series IDs") {
                                const truncateSeriesUID = (uid) => {
                                    if (uid.length <= 10) return uid;
                                    return uid.substring(0, 5) + '...' + uid.substring(uid.length - 5);
                                };
                                return (
                                    <tr key={id} className="hover:bg-gray-50 border-b">
                                        <td className="p-2">
                                            <input
                                                type="checkbox"
                                                checked={isSelected(id)}
                                                onChange={() => !isDisabled && onItemSelect(item)}
                                                disabled={isDisabled}
                                            />
                                        </td>
                                        <td className="p-2">{truncateSeriesUID(item.SeriesInstanceUID)}</td>
                                        <td className="p-2">{VALUE_MAPPINGS.Modality[item.Modality] || item.Modality}</td>
                                        <td className="p-2">{item.collection_id}</td>
                                        <td className="p-2">{item.PatientID}</td>
                                        <td className="p-2">
                                            <a
                                                href={`https://viewer.imaging.datacommons.cancer.gov/slim/studies/${item.StudyInstanceUID}/series/${item.SeriesInstanceUID}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-blue-600 hover:underline"
                                            >
                                                View Image
                                            </a>
                                        </td>
                                    </tr>
                                );
                            }
                        })}
                        </tbody>
                    </table>
                )}

                {title !== "Series IDs" && currentItems.map((item) => {
                    const id = typeof item === 'object' ? item.SeriesInstanceUID : item;
                    return (
                        <div
                            key={id}
                            className={`p-2 cursor-pointer rounded ${
                                isSelected(id) ? 'bg-blue-100' : 'hover:bg-gray-100'
                            } flex items-center`}
                        >
                            <input
                                type="checkbox"
                                checked={isSelected(id)}
                                onChange={() => !isDisabled && onItemSelect(item)}
                                className="mr-2"
                                disabled={isDisabled}
                            />
                            <span className="ml-2">{id}</span>
                        </div>
                    );
                })}
            </div>

            <div className="flex justify-center mt-4 space-x-2">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1 || isDisabled}
                    className="px-3 py-1 rounded bg-gray-200 text-black hover:bg-gray-300 disabled:bg-gray-100"
                >
                    Previous
                </button>
                <span className="px-3 py-1 text-black">
          Page {currentPage} of {Math.max(1, totalPages)}
        </span>
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages || totalPages === 0 || isDisabled}
                    className="px-3 py-1 rounded bg-gray-200 text-black hover:bg-gray-300 disabled:bg-gray-100"
                >
                    Next
                </button>
            </div>
        </div>
    );
};

const ImageRetriever = () => {
    const [filterOptions, setFilterOptions] = useState({});
    const [selectedFilters, setSelectedFilters] = useState({});
    const [collectionIds, setCollectionIds] = useState([]);
    const [selectedCollections, setSelectedCollections] = useState([]);
    const [patientIds, setPatientIds] = useState([]);
    const [selectedPatientIds, setSelectedPatientIds] = useState([]);
    const [seriesData, setSeriesData] = useState([]);
    const [selectedSeriesIds, setSelectedSeriesIds] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pendingFilterUpdates, setPendingFilterUpdates] = useState({});

    useEffect(() => {
        fetchInitialFilterOptions();
    }, []);

    useEffect(() => {
        if (selectedCollections.length >= 0) {
            updatePatientList(selectedCollections);
        }
    }, [selectedCollections]);

    const fetchInitialFilterOptions = async () => {
        setLoading(true);
        try {
            const response = await fetch('/api/filter-options');
            const data = await response.json();

            setFilterOptions({
                ...data,
                Collections: data.collection_id || []
            });
        } catch (error) {
            console.error('Error fetching initial filter options:', error);
        } finally {
            setLoading(false);
        }
    };

    const updateFilterOptions = async () => {
        setLoading(true);
        try {
            const filtersToSend = {...selectedFilters};
            if (filtersToSend.Collections) {
                filtersToSend.collection_id = filtersToSend.Collections;
                delete filtersToSend.Collections;
            }

            const response = await fetch('/api/filter-options', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({filters: filtersToSend})
            });
            const data = await response.json();

            setFilterOptions(prev => ({
                ...prev,
                ...data,
                Collections: data.collection_id || []
            }));

            setCollectionIds(data.collection_id || []);
            setSelectedCollections([]);
            setPatientIds([]);
            setSelectedPatientIds([]);
            setSeriesData([]);
            setSelectedSeriesIds([]);
        } catch (error) {
            console.error('Error updating filter options:', error);
        } finally {
            setLoading(false);
        }
    };

    const updatePatientList = async (currentCollections) => {
        setLoading(true);
        try {
            const filtersToSend = {...selectedFilters};
            if (filtersToSend.Collections) {
                filtersToSend.collection_id = filtersToSend.Collections;
                delete filtersToSend.Collections;
            }

            const response = await fetch('/api/filter-patients', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    filters: {...filtersToSend, collection_id: currentCollections}
                })
            });
            const data = await response.json();
            setPatientIds(data.patient_ids || []);
            setSelectedPatientIds([]);
            setSeriesData([]);
            setSelectedSeriesIds([]);
        } catch (error) {
            console.error('Error updating patient list:', error);
        } finally {
            setLoading(false);
        }
    };

    const handlePatientSelect = async (patientId) => {
        if (!selectedPatientIds.includes(patientId)) {
            setLoading(true);
            try {
                const modality = selectedFilters.Modality || [];
                const queryParams = new URLSearchParams({
                    patient_id: patientId,
                    modality: modality.join(',')
                });

                const response = await fetch(`/api/series-ids?${queryParams}`);
                const data = await response.json();
                setSeriesData(prevData => [...prevData, ...data.series_data]);
            } catch (error) {
                console.error('Error fetching series data:', error);
            } finally {
                setLoading(false);
            }
        } else {
            setSeriesData(prevData => prevData.filter(item => item.PatientID !== patientId));
        }
    };

    const handleSelectAllCollections = useCallback(() => {
        const newSelection = [...collectionIds];
        setSelectedCollections(newSelection);
        updatePatientList(newSelection);
    }, [collectionIds]);

    const handleSelectNoCollections = useCallback(() => {
        setSelectedCollections([]);
        updatePatientList([]);
    }, []);

    const handleSelectAllPatients = useCallback(() => {
        setSelectedPatientIds(patientIds);
        patientIds.forEach(pid => handlePatientSelect(pid));
    }, [patientIds]);

    const handleSelectNoPatients = useCallback(() => {
        setSelectedPatientIds([]);
        setSeriesData([]);
        setSelectedSeriesIds([]);
    }, []);

    const handleSelectAllSeries = useCallback(() => {
        setSelectedSeriesIds(seriesData.map(item => item.SeriesInstanceUID));
    }, [seriesData]);

    const handleSelectNoSeries = useCallback(() => {
        setSelectedSeriesIds([]);
    }, []);

    const handleFilterChange = (field, values) => {
        const newFilters = {
            ...selectedFilters,
            [field]: values.map(v => v.value)
        };
        setPendingFilterUpdates(prev => ({
            ...prev,
            [field]: true
        }));
        setSelectedFilters(newFilters);
    };

    const handleFilterBlur = () => {
        if (Object.keys(pendingFilterUpdates).length > 0) {
            setPendingFilterUpdates({});
            updateFilterOptions();
        }
    };

    const getSelectOptions = (field, options) => {
        return options.map(opt => ({
            value: opt,
            label: VALUE_MAPPINGS[field]?.[opt] || opt
        }));
    };

    const handleCollectionSelect = useCallback((collectionId) => {
        setSelectedCollections(prev => {
            const newSelection = prev.includes(collectionId)
                ? prev.filter(id => id !== collectionId)
                : [...prev, collectionId];
            return newSelection;
        });
    }, []);

    return (
        <div style={containerStyle}>
            <h1 className="text-3xl font-bold mb-6 text-center text-black">Image Retrieval</h1>
            <LoadingOverlay isLoading={loading}/>
            <div className="row">
                <div className="col-lg-6">
                    <div style={sectionStyle}>
                        {Object.entries(filterOptions)
                            .filter(([field]) => field !== 'collection_id')
                            .map(([field, options]) => (
                                <div key={field} className="mb-4">
                                    <label className="block text-sm font-medium mb-2">
                                        {FIELD_TITLES[field]}:
                                    </label>
                                    <Select
                                        isMulti
                                        options={getSelectOptions(field, options)}
                                        value={selectedFilters[field]?.map(v => ({
                                            value: v,
                                            label: VALUE_MAPPINGS[field]?.[v] || v
                                        }))}
                                        onChange={(selected) => handleFilterChange(field, selected || [])}
                                        onBlur={handleFilterBlur}
                                        isDisabled={loading}
                                        closeMenuOnSelect={false}
                                    />
                                </div>
                            ))}
                    </div>
                </div>

                <div className="col-lg-6">
                    {collectionIds.length > 0 && (
                        <PaginatedSection
                            title={FIELD_TITLES.collection_id}
                            items={collectionIds}
                            selectedItems={selectedCollections}
                            onItemSelect={handleCollectionSelect}
                            onSelectAll={handleSelectAllCollections}
                            onSelectNone={handleSelectNoCollections}
                            searchPlaceholder="Search collections..."
                            multiSelect={true}
                            isDisabled={loading}
                        />
                    )}

                    {selectedCollections.length > 0 && (
                        <PaginatedSection
                            title="Patient IDs"
                            items={patientIds}
                            selectedItems={selectedPatientIds}
                            onItemSelect={(item) => {
                                setSelectedPatientIds(prev => {
                                    const newSelection = prev.includes(item)
                                        ? prev.filter(pid => pid !== item)
                                        : [...prev, item];
                                    handlePatientSelect(item);
                                    return newSelection;
                                });
                            }}
                            onSelectAll={handleSelectAllPatients}
                            onSelectNone={handleSelectNoPatients}
                            searchPlaceholder="Search patient IDs..."
                            multiSelect={true}
                            isDisabled={loading}
                        />
                    )}

                    {selectedPatientIds.length > 0 && seriesData.length > 0 && (
                        <PaginatedSection
                            title="Series IDs"
                            items={seriesData}
                            selectedItems={selectedSeriesIds}
                            onItemSelect={(item) => setSelectedSeriesIds(prev => {
                                const newSelection = prev.includes(item.SeriesInstanceUID)
                                    ? prev.filter(id => id !== item.SeriesInstanceUID)
                                    : [...prev, item.SeriesInstanceUID];
                                return newSelection;
                            })}
                            onSelectAll={handleSelectAllSeries}
                            onSelectNone={handleSelectNoSeries}
                            searchPlaceholder="Search series IDs..."
                            multiSelect={true}
                            showDownload={true}
                            isDisabled={loading}
                            onDownload={() => window.alert('Download functionality coming soon!')}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default ImageRetriever;