import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const containerStyle = {
  backgroundColor: '#e6f2ff',
  minHeight: '100vh',
  padding: '20px'
};

const cardStyle = {
  boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
  borderRadius: '0.5rem',
  border: 'none',
  backgroundColor: '#f9f9f9',
};

const cardBodyStyle = {
  backgroundColor: '#f9fafd',
  borderRadius: '0.5rem'
};

const legendStyle = {
  fontSize: '18px',
  fontWeight: '600',
  color: '#333'
};

const labelStyle = {
  fontSize: '14px',
  color: '#333',
  fontWeight: '500',
  marginBottom: '8px'
};

const selectStyle = {
  width: '100%',
  padding: '6px',
  borderRadius: '4px',
  border: '1px solid #ccc',
  fontSize: '14px'
};

const buttonContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  marginTop: '20px'
};

const buttonStyle = {
  backgroundColor: '#0080ff',
  border: 'none',
  color: '#fff',
  padding: '8px 20px',
  fontSize: '16px',
  cursor: 'pointer',
  borderRadius: '4px',
};

const buttonSpacing = {
  marginLeft: '20px'
};

export default function MetabolomicsWorkBench() {
  const [studyId, setStudyId] = useState('ST000865');

  useEffect(() => {
    const last_time = window.last_time;
    if (last_time !== undefined && last_time !== null && last_time !== -2) {
      const last_configure = window.last_configure;
      if (last_configure && last_configure.data_source_type === 'databases') {
        setStudyId(last_configure.project || 'ST000865');
      }
    }
  }, []);

  function backtoMetCraft() {
    const param = {
      data_source_type: 'databases',
      databases_choice: 'Metabolomics',
      project: studyId
    };
    if (window.opener && window.opener.getDRParameter) {
      window.opener.getDRParameter(param);
    }
  }

  return (
    <div style={containerStyle}>
      <div className="row">
        <div className="col-lg-12">
          <div className="card" style={cardStyle}>
            <div className="card-body" style={cardBodyStyle}>
              <fieldset>
                <legend style={legendStyle}>
                  Metabolomics WorkBench
                  <sup title="Default value is recommended." style={{marginLeft:'5px', color:'#666'}}>
                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                  </sup>
                </legend>
                <div style={labelStyle}>Study ID:</div>
                <select
                  id="study_id"
                  value={studyId}
                  onChange={(e)=>setStudyId(e.target.value)}
                  style={selectStyle}
                >
                  <option value="ST000865">ST000865</option>
                  <option value="ST001734">ST001734</option>
                  <option value="ST000291">ST000291</option>
                  <option value="ST001301">ST001301</option>
                </select>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
      <div style={buttonContainerStyle}>
        <button
          type="button"
          id='submit2'
          style={buttonStyle}
          onClick={backtoMetCraft}
        >
          Submit
        </button>
      </div>
    </div>
  );
}