import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from 'react-router-dom';
import axios from 'axios';

import {
    Autocomplete,
    Breadcrumbs,
    CircularProgress,
    IconButton,
    Link,
    TextField,
    Menu,
    MenuItem
} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import {TreeItem, TreeView} from '@mui/x-tree-view';
import {
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    LinearProgress,
    Modal,
    Snackbar,
    Typography,
    ModalDialog,
    ModalClose,
} from '@mui/joy';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';

import fileIcon from './icons/icons8-file-96.png';
import folderIcon from './icons/icons8-folder-96.png';
import zipIcon from './icons/icons8-zip-96.png';
import csvIcon from './icons/icons8-csv-96.png';
import jsonIcon from './icons/icons8-json-96.png';
import downloadFolderIcon from './icons/icons8-downloads-folder-96.png';
import demoFolderIcon from './icons/icons8-test-folder-96.png';

import {v4 as uuidv4} from "uuid";
import JSZip from 'jszip';
import './cloudStorage.css';


function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const size = bytes / Math.pow(k, i);

    // Check if the size is close to 1024 and if there's a higher unit available
    if (size.toFixed(dm) === (1024).toFixed(dm) && i + 1 < sizes.length) {
        return parseFloat((bytes / Math.pow(k, i + 1)).toFixed(dm)) + ' ' + sizes[i + 1];
    }

    return parseFloat(size.toFixed(dm)) + ' ' + sizes[i];
}


const CloudStorage = () => {
    const [csrfToken, setCsrfToken] = useState(null);

    const [renameDialogOpen, setRenameDialogOpen] = useState(false);
    const [renameValue, setRenameValue] = useState('');
    const [openNewDirectoryDialog, setOpenNewDirectoryDialog] = useState(false);
    const [newDirectoryName, setNewDirectoryName] = useState("");
    const [actionMenuAnchor, setActionMenuAnchor] = useState(null);
    const [currentRowId, setCurrentRowId] = useState(null);
    const [actionTaken, setActionTaken] = useState(false);  // track whether an action (download or delete) has been initiated
    const [selectedRows, setSelectedRows] = useState([]);  // track which rows have been selected
    const [selectedButton, setSelectedButton] = useState('my storage');  // track the buttons in the side nav
    const [searchTerm, setSearchTerm] = useState('');  // track the search item
    const location = useLocation();
    const {
        page_mode,
        user_name,
        user_id,
        storage_key,
        storage_space,
        storageData,
        current_path,
        current_fold_name,
        sessionId_back,
        originalOpener_back,
        callback_url
    } = location.state;
    const pageMode = page_mode;
    const storageSpace = storage_space;
    const currentPath = current_path;
    const username = user_name;
    const userId = user_id;
    const [files, setFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadSpeed, setUploadSpeed] = useState(0);
    const [uploadStatus, setUploadStatus] = useState({});
    const [isUploading, setIsUploading] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [filesUploaded, setFilesUploaded] = useState(0);

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteConfirmationChecked, setDeleteConfirmationChecked] = useState(false);
    const [deleteSource, setDeleteSource] = useState(null);  // 'menu' or 'button'
    const [deleteFileList, setDeleteFileList] = useState([]);

    const [moveFileDialogOpen, setMoveFileDialogOpen] = useState(false);
    const [selectedNodeId, setSelectedNodeId] = useState(null); // track the selected node/folder for moving to
    const [completeStorageData, setCompleteStorageData] = useState([]);

    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isUnzipping, setIsUnzipping] = useState(false);
    const [unzippingFileId, setUnzippingFileId] = useState(null);

    const [isDeleting, setIsDeleting] = useState(false);
    const [isCreatingFolder, setIsCreatingFolder] = useState(false);
    const [isMoving, setIsMoving] = useState(false);
    const [isRenaming, setIsRenaming] = useState(false);

    const [storageSpaceUsed, setStorageSpaceUsed] = useState(0);

    const [rows, setRows] = useState([]); // track the rows in the file list
    const [fileTree, setFileTree] = useState({});
    const [demoRootPath, setDemoRootPath] = useState(`space_stage_version/${storage_key}/useruploads/Demo Data/`);

    const getProgressBarColor = () => {
        const value = Math.min(Number((storageSpaceUsed / storageSpace) * 100), 100);
        if (value > 90) return '#ff3b2f';
        if (value > 70) return '#ffcc02';
        return 'primary';
    };

    useEffect(() => {
        const pathsToDelete = deleteSource === 'menu' && currentRowId
            ? [rows.find(row => row.id === currentRowId)?.path.replace("space_stage_version/" + storage_key + "/useruploads/", "My Storage/")]
            : selectedRows.map(rowId => {
                const fileToDelete = rows.find(row => row.id === rowId);
                return fileToDelete?.path.replace("space_stage_version/" + storage_key + "/useruploads/", "My Storage/");
            });
        setDeleteFileList(pathsToDelete);
    }, [selectedRows, deleteSource, currentRowId]);

    useEffect(() => {
        document.title = getTabName(page_mode);
    }, [page_mode]);

    function getTabName(mode) {
        switch (mode) {
            case 'file_management':
                return 'Data Manager';
            case 'project_management':
                return 'Project Manager';
            case 'file_selection':
                return 'File Selection';
            case 'project_selection':
                return 'Project Selection';
            default:
                return 'MetaboStorage';
        }
    }


    useEffect(() => {
        axios.get('/api/get-csrf-token/')
            .then(response => {
                setCsrfToken(response.data.csrfToken);
            });
    }, []);


    const getParentPath = (path, type) => {
        const parts = path.split('/');
        parts.pop();

        if (type !== 'File') {
            parts.pop();
        }

        return parts.join('/') + '/';
    };


    useEffect(() => {
        // Calculate the total size, ignoring sizes that are '-'
        const totalSize = completeStorageData.reduce((sum, item) => {
            if (!item.Path.startsWith(demoRootPath)) { // Check if the path starts with demoRootPath
                const size = +item.Size; // Attempt to convert to a number
                return !isNaN(size) ? sum + size : sum; // Add to sum if size is a valid number
            }
            return sum; // Return current sum unchanged if path does not start with demoRootPath
        }, 0);

        setStorageSpaceUsed(totalSize);

        const transformedData = completeStorageData
            .filter(item => item.parentPath === currentPath)
            .map((item, index) => ({
                id: index + 1,
                type: item.Type,
                fileName: item.FileName,
                size: item.Size,
                path: item.Path,
                creationDate: new Date(item.CreationDateTime),
                encrypted_id: item.id,
                parentPath: item.parentPath
            }));

        setRows(transformedData);
    }, [completeStorageData, storage_key]);


    useEffect(() => {
        const fetchData = async (key) => {
            try {
                const params = new URLSearchParams({storage_key: key}).toString();
                const response = await axios.get(`/api/get-complete-storage-data/?${params}`);
                const completeData = response.data.complete_data;

                // Add parent path
                return completeData.map(item => ({
                    ...item,
                    parentPath: getParentPath(item.Path, item.Type)
                }));
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const loadData = async () => {
            if (storage_key) {
                const userDataPromise = fetchData(storage_key);

                if (storage_key !== '5MDSLse9kgHEewgu') {
                    const demoFolderPromise = fetchData('5MDSLse9kgHEewgu');

                    // Fetch both sets of data in parallel and merge them
                    Promise.all([userDataPromise, demoFolderPromise]).then(([userData, demoFolder]) => {
                        const updatedDemoFolder = demoFolder.map(item => ({
                            ...item,
                            StorageID: storage_key,
                            Path: item.Path.replace('5MDSLse9kgHEewgu', storage_key),
                            parentPath: item.parentPath.replace('5MDSLse9kgHEewgu', storage_key)
                        }));
                        setCompleteStorageData([...userData, ...updatedDemoFolder]);
                    });
                } else {
                    // Fetch only the user data
                    userDataPromise.then(userData => {
                        setCompleteStorageData(userData);
                    });
                }
            }
        };

        loadData();
    }, [storageData]);


    useEffect(() => {
        (async () => {
            if (storageSpaceUsed !== 0) {
                try {
                    const response = await axios.post('/api/update/user_storage_space_remaining/', {
                        parameters: {
                            storage_key: storage_key,
                            remaining: storageSpace - storageSpaceUsed
                        },
                        request_name: 'update_space_remaining',
                        job_id: uuidv4()
                    });

                    console.log(response.data);
                } catch (error) {
                    console.error("API call failed", error);
                }
            }
        })();
    }, [storageSpaceUsed]);


    const buildFileTree = (items, currPath = 'space_stage_version/' + storage_key + '/useruploads/') => {
        const filteredItems = items.filter(item => item.parentPath === currPath);

        // Map each item to its tree structure
        const tree = filteredItems.map(item => {
            const node = {
                id: item.id,
                name: item.FileName,
                type: item.Type,
                path: item.Path,
                parentPath: item.parentPath,
                // Initialize children for folders
                ...(item.Type === "Folder" && {children: []})
            };

            // If the item is a folder, recursively build its children
            if (item.Type === "Folder" || item.Type === "Downloads" || item.Type === "Demo") {
                node.children = buildFileTree(items, item.Path);
            }
            return node;
        });

        return tree;
    };


    useEffect(() => {
        const initialTree = {
            id: 'root',
            name: 'My Storage',
            type: 'Folder',
            path: 'space_stage_version/' + storage_key + '/useruploads/',
            children: buildFileTree(completeStorageData)
        };
        setFileTree(initialTree);
    }, [completeStorageData]);

    const renderTree = (nodes) => (
        <TreeItem
            key={nodes.id}
            nodeId={nodes.id}
            label={nodes.name}
            path={nodes.path}
            icon={<img
                src={
                    nodes.name && nodes.name.endsWith('.zip') ? zipIcon :
                        nodes.name.endsWith('.csv') ? csvIcon :
                            nodes.name.endsWith('.json') ? jsonIcon :
                                nodes.type === 'File' ? fileIcon :
                                    nodes.type === 'Downloads' ? downloadFolderIcon :
                                        nodes.type === 'Demo' ? demoFolderIcon :
                                            folderIcon
                }
                alt=''
                style={{width: '24px', height: '24px'}}/>}
        >
            {Array.isArray(nodes.children)
                ? nodes.children.map((node) => renderTree(node))
                : null}
        </TreeItem>
    );

    const pathSegments = currentPath.replace("space_stage_version/" + storage_key + "/useruploads/", "My Storage/").split('/').filter(Boolean); // Split and remove any empty strings
    const breadcrumbLinks = pathSegments.map((segment, index) => {
        const folderPath = pathSegments.slice(0, index + 1).join('/') + '/';
        return (
            <Link
                underline="hover"
                key={index}
                color="inherit"
                style={{cursor: 'pointer'}}
                onClick={() => handleBreadcrumbClick(folderPath.replace("My Storage/", "space_stage_version/" + storage_key + "/useruploads/"))}
            >
                {segment}
            </Link>
        );
    });

    const columns = [
        {
            field: 'type',
            headerName: 'Type',
            flex: 0.4,
            renderCell: (params) => {
                if (params.row.id === unzippingFileId && isUnzipping) {
                    return <CircularProgress size={24}/>;
                }

                if (params.row.fileName.endsWith('.zip')) {
                    return <img src={zipIcon} alt="zipFile" style={{width: '30px', height: '30px'}}/>;
                } else if (params.row.fileName.endsWith('.csv')) {
                    return <img src={csvIcon} alt="csvFile" style={{width: '30px', height: '30px'}}/>;
                } else if (params.row.fileName.endsWith('.csv')) {
                    return <img src={jsonIcon} alt="jsonFile" style={{width: '30px', height: '30px'}}/>;
                } else if (params.value === 'File') {
                    return <img src={fileIcon} alt="File" style={{width: '30px', height: '30px'}}/>
                } else if (params.value === 'Folder') {
                    return <img src={folderIcon} alt="Folder" style={{width: '30px', height: '30px'}}/>;
                } else if (params.value === 'Downloads') {
                    return <img src={downloadFolderIcon} alt="DownloadFolder" style={{width: '30px', height: '30px'}}/>;
                } else if (params.value === 'Demo') {
                    return <img src={demoFolderIcon} alt="DemoFolder" style={{width: '30px', height: '30px'}}/>;
                } else {
                    return params.value; // Display the value as-is for other types
                }
            },
        },
        {field: 'fileName', headerName: 'File Name', flex: 1},
        {
            field: 'creationDate',
            headerName: 'Date of Creation',
            type: 'date',
            flex: 0.6,
            renderCell: (params) => {
                const utcDate = new Date(params.value + 'Z');
                return utcDate.toLocaleString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false // Use 24-hour format
                });
            }
        },
        {
            field: 'size',
            headerName: 'Size',
            type: 'number',
            flex: 0.5,
            valueGetter: (params) => {
                if (params.row.type === 'Folder' || params.row.type === 'Downloads' || params.row.type === 'Demo') {
                    return '—';
                }
                return formatBytes(params.value);
            },
        },
        {
            field: 'action',
            headerName: '',
            flex: 0.2,
            renderCell: (params) => (
                <>
                    <IconButton
                        aria-label="more"
                        aria-controls="action-menu"
                        aria-haspopup="true"
                        onClick={(e) => {
                            e.stopPropagation();  // Prevent event from bubbling up to the DataGrid's onCellClick
                            handleActionMenuClick(e, params.row.id);
                        }}>
                        <MoreVertIcon/>
                    </IconButton>
                    <Menu
                        id="action-menu"
                        anchorEl={actionMenuAnchor}
                        keepMounted
                        open={Boolean(actionMenuAnchor) && currentRowId === params.row.id}
                        onClose={handleActionMenuClose}
                    >
                        {(params.row.type !== 'Downloads' && !params.row.path.startsWith(demoRootPath)) && (
                            <MenuItem onClick={(e) => {
                                e.stopPropagation();
                                openRenameDialog(params.row.fileName);
                                handleActionMenuClose();
                            }}>Rename</MenuItem>
                        )}
                        <Modal open={renameDialogOpen}
                               onClose={() => setRenameDialogOpen(false)}
                               onClick={(event) => event.stopPropagation()}
                        >
                            <ModalDialog>
                                <ModalClose/>
                                <Typography level="h4">Rename Item</Typography>
                                <Typography>Enter the new name for the item.</Typography>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="rename"
                                    label="New Name"
                                    type="text"
                                    defaultValue={renameValue}
                                    onChange={(e) => setRenameValue(e.target.value)}
                                />
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: 2, marginTop: 2}}>
                                    <Button onClick={() => setRenameDialogOpen(false)}
                                            color="primary"
                                            variant="outlined"
                                    >
                                        Cancel
                                    </Button>
                                    <Button onClick={handleRename} color="primary" variant="outlined" loading={isRenaming} loadingPosition="start">
                                        Rename
                                    </Button>
                                </Box>
                            </ModalDialog>
                        </Modal>
                        {(params.row.type !== 'Downloads' && !params.row.path.startsWith(demoRootPath)) && (
                            <MenuItem onClick={(e) => {
                                e.stopPropagation();
                                setMoveFileDialogOpen(true);
                                handleActionMenuClose();
                            }}>Move to ...</MenuItem>
                        )}
                        <Modal open={moveFileDialogOpen}
                               onClose={() => setMoveFileDialogOpen(false)}
                               onClick={(event) => event.stopPropagation()}
                        >
                            <ModalDialog sx={{width: '50%', maxHeight: '90vh', overflow: 'auto'}}>
                                <ModalClose/>
                                <Typography level="h4">Move the file to...</Typography>
                                <TreeView
                                    aria-label="Move To Tree View"
                                    defaultEndIcon={<div style={{width: 24}}/>}
                                    onNodeSelect={handleNodeSelect}
                                    sx={{overflowY: 'auto', flexGrow: 1, minHeight: 0}}
                                >
                                    {renderTree(fileTree)}
                                </TreeView>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: 2, marginTop: 2}}>
                                    <Button onClick={() => setMoveFileDialogOpen(false)} color="primary"
                                            variant="outlined">
                                        Cancel
                                    </Button>
                                    <Button onClick={handleFileMove} color="primary" variant="outlined"
                                            loading={isMoving} loadingPosition="start">
                                        Confirm
                                    </Button>
                                </Box>
                            </ModalDialog>
                        </Modal>
                        {params.row.fileName.endsWith('.zip') && (
                            <MenuItem onClick={(e) => {
                                e.stopPropagation();
                                if (!isUnzipping) {
                                    handleFileUnzip();
                                    if (params.row.size > 20000000) {
                                        showErrorDialog("Unzipping large files can be time-consuming. Your file is now being processed. Wait until the unzipped folder appears before proceeding. Thank you for your patience.");
                                    }
                                } else {
                                    showErrorDialog("Another file is currently being unzipped. Please wait until the current process is complete before attempting to unzip another file.");
                                }
                                handleActionMenuClose();
                            }}>Unzip</MenuItem>
                        )}
                        <MenuItem onClick={(e) => {
                            e.stopPropagation();
                            handleFileDownload('menu', [selectedRows]);
                            handleActionMenuClose();
                        }}>Download</MenuItem>
                        {(params.row.type !== 'Downloads' && !params.row.path.startsWith(demoRootPath)) && (
                            <MenuItem onClick={(e) => {
                                e.stopPropagation();
                                setDeleteSource('menu');
                                setDeleteDialogOpen(true);
                                handleActionMenuClose();
                            }} style={{color: 'red'}}>Delete</MenuItem>
                        )}
                        <Modal open={deleteDialogOpen}
                               onClose={(e) => {
                                   setDeleteDialogOpen(false);
                                   setDeleteConfirmationChecked(false);
                               }}
                               onClick={(event) => event.stopPropagation()}
                        >
                            <ModalDialog>
                                <ModalClose/>
                                <Typography level="h4">Confirm Deletion</Typography>
                                <Typography style={{color: 'red', fontWeight: 'bold'}}>
                                    Deleting the specified file/folder can't be undone. All selected files/folders will
                                    be permanently deleted. To confirm, please check the box below.
                                </Typography>
                                {deleteFileList.length > 0 && (
                                    <>
                                        <Typography>You are about to delete the following:</Typography>
                                        <ul>
                                            {deleteFileList.map((filePath, index) => (
                                                <li key={index}>{filePath}</li>
                                            ))}
                                        </ul>
                                    </>
                                )}
                                <Checkbox variant="outlined"
                                          checked={deleteConfirmationChecked}
                                          onChange={(e) => setDeleteConfirmationChecked(e.target.checked)}
                                          label="Permanently delete selected files/folders."
                                />
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: 2, marginTop: 2}}>
                                    <Button variant="outlined"
                                            onClick={(e) => {
                                                setDeleteDialogOpen(false);
                                                setDeleteConfirmationChecked(false);
                                                e.stopPropagation();
                                            }}
                                            color="primary"
                                    >
                                        Cancel
                                    </Button>
                                    <Button variant="outlined"
                                            color="danger"
                                            loading={isDeleting}
                                            loadingPosition="start"
                                            onClick={async () => {
                                                if (deleteConfirmationChecked) {
                                                    if (deleteSource === 'menu') {
                                                        await handleFileDelete([currentRowId]);
                                                        setSelectedRows(prevSelectedRows =>
                                                            prevSelectedRows.filter(rowId => rowId !== currentRowId)
                                                        );
                                                    } else if (deleteSource === 'button') {
                                                        await handleFileDelete(selectedRows);
                                                        setSelectedRows([]);
                                                    }
                                                    setDeleteDialogOpen(false);
                                                    setDeleteConfirmationChecked(false);
                                                }
                                            }}
                                            disabled={!deleteConfirmationChecked}
                                    >
                                        Confirm Deletion
                                    </Button>
                                </Box>
                            </ModalDialog>
                        </Modal>
                    </Menu>
                </>
            ),
        },
    ];

    const handleBreadcrumbClick = (folderPath) => {
        setSelectedRows([]);
        const job_id = uuidv4();

        // Extract the folder name from the folderPath
        const folderName = folderPath.split('/').filter(Boolean).pop();

        let encrypted_id;
        if (folderPath === 'space_stage_version/' + storage_key + '/useruploads/') {
            encrypted_id = storage_key;
        } else {
            const folderToGo = completeStorageData.find(entry => entry.Path === folderPath);
            encrypted_id = folderToGo.id;
        }

        axios.post(
            '/api/storage/${encrypted_id}/',
            {
                parameters: {
                    folder_name: folderName,
                    encrypted_id: encrypted_id,
                    current_path: folderPath
                },
                request_name: 'enter_folder',
                job_id: job_id
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            })
            .then(response => {
                if (response.data.status === "success") {
                    // Update the rows state with the new folder content
                    const data_info = response.data.response_data;

                    // Construct your data from the response
                    const userData = {
                        page_mode: pageMode,
                        user_name: user_name,
                        user_id: userId,
                        storage_key: storage_key,
                        storage_space: storageSpace,
                        storageData: data_info.storage_json,
                        current_path: folderPath,
                        current_fold_name: folderName,
                        sessionId_back: sessionId_back,
                        callback_url: callback_url,
                    };

                    // Navigate to CloudStorage with the unique URL
                    navigate(`/storage/${encrypted_id}`, {state: userData});
                } else {
                    console.error("Error enter:\n", response.data.response_data.error_message);
                }
            })
            .catch(error => {
                console.error("Error fetching folder content:", error);
            });
    };


    const showErrorDialog = (message) => {
        setErrorMessage(message);
        setShowErrorSnackbar(true);
    };

    const closeErrorDialog = () => {
        setShowErrorSnackbar(false);
    };

    const handleNodeSelect = (event, nodeId) => {
        setSelectedNodeId(nodeId);
        event.stopPropagation();
    };


    const findNodeInTree = (tree, nodeId) => {
        if (tree.id === nodeId) {
            return tree;
        }

        // If the tree has children, recursively search through them
        if (tree.children && tree.children.length > 0) {
            for (const child of tree.children) {
                const found = findNodeInTree(child, nodeId);
                if (found) return found; // Return the found node
            }
        }

        return null;
    };


    const handleFileMove = () => {
        // ---initialize move process start---
        const job_id = uuidv4();
        // Get the file/folder to move
        const fileToMove = rows.find(row => row.id === currentRowId);

        if (!fileToMove) {
            showErrorDialog("Selected file/folder not found");
            return;
        }

        // Get the destination folder
        const destinationFolder = findNodeInTree(fileTree, selectedNodeId);
        if (!destinationFolder) {
            showErrorDialog("Destination folder not found");
            return;
        }

        // Check if moving a folder to its sub-folder
        if (fileToMove.type === "Folder" && destinationFolder.path.startsWith(fileToMove.path)) {
            showErrorDialog("Failed to move folder to its sub-directory.");
            return;
        }

        // Check if move file or folder to Demo folder
        if (destinationFolder.path.startsWith(demoRootPath)) {
            showErrorDialog("Can't move file/folder to 'Demo Data' folder");
            return;
        }

        // Check if the destination folder is actually a folder
        if (destinationFolder.type !== "Folder" && destinationFolder.type !== "Downloads") {
            showErrorDialog("Destination is not a folder");
            return;
        }

        // Check if the destination path is the same as the current path
        if (fileToMove.parentPath === destinationFolder.path) {
            showErrorDialog("File/Folder is already in the selected folder");
            return;
        }

        // Check if the destination path is the same as the moved folder
        if (fileToMove.path === destinationFolder.path) {
            showErrorDialog("Failed to move folder to its own");
            return;
        }

        // Check file name duplication
        const filesAndFolders = completeStorageData.filter(
            item => item.parentPath === destinationFolder.path
        );
        if (filesAndFolders.some(item => item.FileName === fileToMove.fileName)) {
            showErrorDialog("File/Folder with the same name is already existed in the selected directory");
            return;
        }

        // Check if move folder named 'Demo' to root directory
        const homePath = `space_stage_version/${storage_key}/useruploads/`
        if (destinationFolder.path === homePath && fileToMove.type === 'Folder' && fileToMove.fileName === 'Demo Data') {
            showErrorDialog("Invalid Folder Name: Folder name can't be 'Demo Data' in the root directory.")
            return;
        }

        setIsMoving(true);
        setSnackbarMessage(`Moving ${fileToMove.type}: ${fileToMove.fileName}`);
        setSnackbarOpen(true);
        // ---initialize move process end---
        // ---send move request start---
        // API request to move the file/folder
        axios.post('/api/move_file/', {
                parameters: {
                    user_id: userId,
                    fileType: fileToMove.type,
                    fileName: fileToMove.fileName,
                    sourcePath: fileToMove.path,
                    destinationPath: destinationFolder.path,
                    start_datetime: new Date()
                },
                request_name: fileToMove.type === 'File' ? 'move_file' : 'move_folder',
                job_id: job_id
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            })
            // ---send move request end---
            // ---handle move response start---
            .then(response => {
                if (response.data.status === "success") {
                    // Update the UI accordingly
                    // Remove the moved item from its current location in 'rows'
                    setRows(prevRows => prevRows.filter(row => row.id !== fileToMove.id));

                    setIsMoving(false);
                    setMoveFileDialogOpen(false);
                } else {
                    setIsMoving(false);
                    console.error("Error moving file/folder:\n", response.data.response_data.error_message);
                }

                handleBreadcrumbClick(current_path);
                setSnackbarOpen(false);
            })
            .catch(error => {
                setIsMoving(false);
                console.error("Error moving file/folder:", error);
                setSnackbarOpen(false);
            });
        // ---handle move response end---
    };


    const handleRename = () => {
        // ---initialize renaming process start---
        const job_id = uuidv4();
        const currentRow = rows.find(row => row.id === currentRowId);
        const file_path_to_rename = currentRow.path;
        const file_type = currentRow.type;
        const curr_name = currentRow.fileName;
        let requestName = 'rename_file';
        const homePath = `space_stage_version/${storage_key}/useruploads/${curr_name}/`

        // Determine which API endpoint to call based on file_type
        let apiEndpoint = '/api/rename/file/';
        if (file_type === 'Folder') {
            apiEndpoint = '/api/rename/folder/';
            requestName = 'rename_folder';
        }

        if (renameValue === '') {
            showErrorDialog("Invalid Filename: File name can't be empty.")
            return;
        }

        if (curr_name === renameValue) {
            setRenameValue('');
            setRenameDialogOpen(false);
            return;
        }

        if (file_type === 'Folder' && file_path_to_rename === homePath && renameValue === 'Demo Data') {
            showErrorDialog("Invalid Folder Name: Folder name can't be 'Demo Data' in the root directory.")
            return;
        }

        setSnackbarMessage(`Renaming ${file_type}: ${curr_name} to ${renameValue}.`);
        setSnackbarOpen(true);
        setIsRenaming(true);
        // ---initialize renaming process end---
        // ---send rename request start---
        // Send a POST request to the appropriate Django API endpoint
        axios.post(apiEndpoint, {
                parameters: {
                    user_id: userId,
                    path: file_path_to_rename,
                    type: file_type,
                    currName: curr_name,
                    newName: renameValue,
                    start_datetime: new Date()
                },
                request_name: requestName,
                job_id: job_id
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            })
            // ---send rename request end---
            // ---handle rename response start---
            .then(response => {
                setIsRenaming(false);
                if (response.data.status === "duplicated name") {
                    // If the server indicates a duplicated name, show the alert dialog
                    showErrorDialog("A file or folder with this name already exists. Please choose a different name.");
                    return;
                }

                if (response.data.status === "success") {
                    // Update the rows state to show the new name and new path
                    const updatedRows = rows.map(row => {
                        if (row.id === currentRowId) {
                            const newPath = response.data.response_data.new_path;
                            return {...row, fileName: renameValue, path: newPath};
                        }
                        return row;
                    });
                    setRows(updatedRows);
                    setRenameValue('');
                    setRenameDialogOpen(false);
                    console.log("Successfully renamed:", response.data);
                }

                handleBreadcrumbClick(current_path);
                setSnackbarOpen(false);
            })
            .catch(error => {
                console.error("Error renaming:", error);
                setSnackbarOpen(false);
            });
        // ---handle rename response end---
    };

    const openRenameDialog = (fileName) => {
        setRenameValue(fileName);
        setRenameDialogOpen(true);
    };


    const handleNewDirectory = () => {
        setOpenNewDirectoryDialog(true);
    };

    const handleDirectoryNameChange = (event) => {
        setNewDirectoryName(event.target.value);
    };

    const addNewDirectory = () => {
        // ---validate directory name---
        // Generate a unique identifier for the job
        const job_id = uuidv4();
        const homePath = `space_stage_version/${storage_key}/useruploads/`

        if (current_path === homePath && newDirectoryName === 'Demo Data') {
            showErrorDialog("Invalid Folder Name: Folder name can't be 'Demo Data' in the root directory.")
            return;
        }

        if (newDirectoryName.trim() === '') {
            showErrorDialog("Invalid Folder Name: Folder name must contain at least one character.")
            return;
        }

        setIsCreatingFolder(true);
        // ---validate directory name end---

        // ---create directory via API start---
        // Make an API call to create a new directory
        axios.post(
            '/api/create_folder/',
            {
                parameters: {
                    user_id: userId,
                    name: newDirectoryName,       // Name of the new directory
                    storage_path: current_path,   // Path where the directory should be created
                    storage_key: storage_key,      // Key for the storage
                    start_datetime: new Date()
                },
                request_name: 'create_folder', // action name
                job_id: job_id					// Unique job identifier
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            })
            // ---create directory via API end---
            // ---handle create directory API response---
            .then(response => {
                if (response.data.status === "duplicated name") {
                    setIsCreatingFolder(false);
                    showErrorDialog("A folder with this name already exists. Please choose a different name.")
                }

                if (response.data.status === "success") {
                    const newRow = {
                        id: rows.length + 1,
                        type: 'Folder',
                        fileName: newDirectoryName,
                        creationDate: new Date(),
                        size: 0,
                        path: current_path + '/' + newDirectoryName + '/',
                    };
                    setIsCreatingFolder(false);
                    setMoveFileDialogOpen(false);
                    // Update the table to display the new directory
                    setRows(prevRows => [...prevRows, newRow]);

                    // Reset directory name state and close the dialog
                    setNewDirectoryName("");
                    setOpenNewDirectoryDialog(false);
                } else {
                    setMoveFileDialogOpen(false);
                    // Log the error if directory creation failed
                    console.error("Error creat folder:\n", response.data.response_data.error_message);
                }

                handleBreadcrumbClick(current_path);
            })
            .catch(error => {
                setMoveFileDialogOpen(false);
                console.error("Error creat folder:", error);
            });
        // ---handle create directory API response end---
    };


    const handleActionMenuClick = (event, rowId) => {
        setCurrentRowId(rowId);
        setActionMenuAnchor(event.currentTarget);
        event.stopPropagation(); // This will prevent the event from reaching the row
    };


    const handleActionMenuClose = (event) => {
        setActionMenuAnchor(null);
        event && event.stopPropagation();
    };


    const navigate = useNavigate();

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleFileUpload = async (e) => {
        // ---initialize file upload process---
        setFilesUploaded(0);
        const selectedFiles = [...e.target.files];
        let currentSize = storageSpaceUsed;
        let newUploadStatus = {...uploadStatus};
        let exceeded = false;

        for (const file of selectedFiles) {
            currentSize += file.size;
            if (currentSize > storageSpace) {
                exceeded = true;
                break;
            }
            newUploadStatus[file.name] = {
                progress: 0,
                speed: 0,
                isUploading: true,
            };
        }

        if (exceeded) {
            showErrorDialog('Error: File upload exceeds available storage space.');
            return;
        }

        setUploadStatus(newUploadStatus);
        const fileCount = selectedFiles.length;
        setSnackbarMessage(`Uploading ${fileCount} file${fileCount !== 1 ? 's' : ''}...`);
        setSnackbarOpen(true);
        // ---initialize file upload process end---

        // ---process file upload---
        for (const [index, file] of selectedFiles.entries()) {
            const formData = new FormData();
            formData.append('user_id', userId);
            formData.append('start_datetime', new Date().toISOString());
            formData.append('file_path', current_path);
            formData.append('storage_key', storage_key);
            formData.append('file', file);
            formData.append('request_name', 'upload_file');
            formData.append('job_id', uuidv4());

            try {
                const response = await axios.post(
                    '/api/upload/file/',
                    formData,
                    {
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'X-CSRFToken': csrfToken
                        },
                        onUploadProgress: (progressEvent) => {
                            // Update progress here
                        }
                    }
                );

                if (response.data.status === "success") {
                    let uniqueFileName = response.data.file_name;
                    const newRow = {
                        id: uuidv4(),
                        type: 'File',
                        fileName: uniqueFileName,
                        creationDate: new Date(),
                        size: file.size,
                        path: current_path + '/' + uniqueFileName,
                    };

                    setRows(prevRows => [...prevRows, newRow]);
                    setFilesUploaded(prev => prev + 1);
                    const remainingFiles = fileCount - (index + 1);
                    setSnackbarMessage(`${remainingFiles} file${remainingFiles !== 1 ? 's' : ''} remaining...`);
                } else {
                    setSnackbarMessage('Error uploading files.');
                    setTimeout(() => setSnackbarOpen(false), 3000);
                    console.error("Error Upload File:\n", response.data.response_data.error_message);
                }
            } catch (error) {
                console.error('Error uploading files:', error);
                setSnackbarMessage('Error uploading some files.');
                setTimeout(() => setSnackbarOpen(false), 3000);
            } finally {
                handleBreadcrumbClick(current_path);
                // Update the upload status
                setUploadStatus(prevStatus => {
                    const updatedStatus = {...prevStatus};
                    updatedStatus[file.name].isUploading = false;
                    return updatedStatus;
                });

            }
        }
        // ---process file upload end---

        // ---finalize file upload session---
        // Close the snackbar when all files are uploaded
        setSnackbarOpen(false);
        // Reset the value of the input to allow the same file to be selected again
        e.target.value = null;
        // ---finalize file upload session end---
    };


    const handleDirectoryUpload = async (e) => {
        // ---initialize directory upload process---
        // Create a new JSZip instance
        const zip = new JSZip();
        const selectedFiles = [...e.target.files].filter(file => file.name !== '.DS_Store');
        const job_id = uuidv4();

        let newRows = [...rows];

        // Calculate total size of selected files
        const totalSize = selectedFiles.reduce((sum, file) => sum + file.size, 0);

        // Check if total size exceeds available storage space
        if (totalSize > storageSpace) {
            showErrorDialog('Error: File upload exceeds available storage space.');
            return;
        }

        // Initialize root folder name
        let folderName = '';

        // Process each file
        selectedFiles.forEach((file, index) => {
            const relativePath = file.webkitRelativePath || file.name;
            zip.file(relativePath, file);

            // Extract the folder name from the first file's relative path
            if (index === 0) {
                const pathParts = relativePath.split('/');
                folderName = pathParts[0];
            }
        });

        // check in the front end, bc sometimes zipping huge files are time-consuming
        if (rows.some(item => item.path === current_path + folderName + '/')) {
            setSnackbarOpen(false);
            showErrorDialog("A folder with this name already exists. Please choose a different name.");
            return;
        }

        setSnackbarMessage('Gathering and compressing files for upload.');
        setSnackbarOpen(true);
        // ---initialize directory upload process end---

        // ---compress and prepare files start---
        try {
            // Generate the zip file
            const content = await zip.generateAsync({type: 'blob'});
            const formData = new FormData();
            formData.append('user_id', userId);
            formData.append('start_datetime', new Date().toISOString());
            formData.append('file_path', current_path);
            formData.append('storage_key', storage_key);
            formData.append('file', content, `${folderName}.zip`);
            formData.append('request_name', 'upload_folder');
            formData.append('job_id', job_id);

            setSnackbarMessage(`Uploading Folder: ${folderName}.`);

            const response = await axios.post('/api/upload/folder/', formData, {
                withCredentials: true,
                headers: {'X-CSRFToken': csrfToken},
                onUploadProgress: (progressEvent) => {
                    // Handle progress
                }
            });

            // Handle the response from the server
            if (response.data.status === "success") {
                console.log("Folder zipped and uploaded successfully.");
                const newRow = {
                    id: newRows.length + 1,
                    type: 'Folder',
                    fileName: folderName,
                    creationDate: new Date(),
                    size: '-',
                    path: current_path + '/' + folderName,
                };
                newRows.push(newRow);
                setRows(newRows);
                setSnackbarOpen(false);
            } else {
                console.error("Error uploading zipped folder:\n", response.data.response_data.error_message);
            }
        } catch (error) {
            console.error('Error zipping or uploading files:', error);
        }
        // ---compress and prepare files end---

        // ---finalize directory upload session start---
        handleBreadcrumbClick(current_path);
        // Reset the value of the input to allow the same file to be selected again
        e.target.value = null;
        // ---finalize directory upload session end---
    };


    const handleFileDownload = (source, selectedRowIds) => {
        // ---prepare files for download start---
        let rowsToDownload;
        if (source === 'menu') {
            // If the source is the menu, download only the current row
            const currentRow = rows.find(row => row.id === currentRowId);
            if (!currentRow) return;
            rowsToDownload = [currentRow];
        } else if (source === 'button') {
            // If the source is the button, download all selected rows
            rowsToDownload = rows.filter(row => selectedRowIds.includes(row.id));
        }

        // Prepare a list of file paths to be sent to the server
        const filePaths = rowsToDownload.map(row => {
            const originalPath = row.path;
            if (originalPath.startsWith(demoRootPath)) {
                // Modify the path by replacing the root path with a new key
                const newPath = `space_stage_version/5MDSLse9kgHEewgu/useruploads/Demo Data/`;
                return originalPath.replace(demoRootPath, newPath);
            }
            return originalPath;
        });

        const job_id = uuidv4();

        // Before starting the file upload process
        const fileCount = filePaths.length;
        setSnackbarMessage(`Zipping and downloading ${fileCount} file${fileCount !== 1 ? 's' : ''}`);
        setSnackbarOpen(true);
        // ---prepare files for download end---

        // ---request file zipping and downloading start---
        // Send a request to the server to zip and download the specified files
        axios.post(
            '/api/zip_and_download/',
            {
                parameters: {
                    user_id: userId,
                    file_paths: filePaths,
                    start_datetime: new Date()
                },
                request_name: 'zip_and_download',
                job_id: job_id
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true,
                responseType: 'blob'  // Indicate that the response data should be treated as a binary blob
            }
            // ---request file zipping and downloading end---
            // ---initiate file download start---
        ).then(response => {
            // Create a link element to initiate the download
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([response.data]));
            link.download = 'download.zip';  // Suggest a name for the downloaded zip file
            link.style.display = 'none';  // Hide the link
            document.body.appendChild(link);  // Append the link to the document
            link.click();  // Initiate the download
            document.body.removeChild(link);  // Clean up by removing the link
            setSnackbarOpen(false);
        }).catch(error => {
            console.error("Error downloading files:", error);
        });
        // ---initiate file download end---
    };


    const handleFileDelete = async (selectedRowIds) => {
        // ---initialize deletion process start---
        const deletePromises = selectedRowIds.map(rowId => {
            const fileToDelete = rows.find(row => row.id === rowId);
            if (!fileToDelete) return Promise.resolve();  // Resolve immediately if the file is not found

            const filePathToDelete = fileToDelete.path;
            const fileType = fileToDelete.type;
            const job_id = uuidv4();

            const fileCount = selectedRowIds.length;
            setIsDeleting(true);
            setSnackbarMessage(`Deleting ${fileCount} file${fileCount !== 1 ? 's' : ''}`);
            setSnackbarOpen(true);
            // ---initialize deletion process end---

            // ---send deletion requests---
            // Return the DELETE request promise
            return axios.delete(
                `/api/delete/file/?path=${encodeURIComponent(filePathToDelete)}&type=${encodeURIComponent(fileType)}&user_id=${encodeURIComponent(userId)}&start_datetime=${encodeURIComponent(new Date().toISOString())}&request_name=delete_file&job_id=${job_id}`,
                {
                    headers: {'X-CSRFToken': csrfToken},
                    withCredentials: true
                }
                // ---send deletion requests end---
                // ---finalize deletion process start---
            ).then(response => {
                if (response.data.status === "success") {
                    // This will be handled after all promises resolve
                    setSnackbarOpen(false);
                    setIsDeleting(false);
                    return {success: true, rowId};
                } else {
                    setTimeout(() => setSnackbarOpen(false), 3000);
                    setIsDeleting(false);
                    console.error("Error delete:\n", response.data.response_data.error_message);
                    return {success: false, rowId};
                }
            }).catch(error => {
                setIsDeleting(false);
                console.error("Error deleting file:", error);
                return {success: false, rowId};
            });
            // ---finalize deletion process end---
        });

        // Wait for all delete operations to complete
        const results = await Promise.all(deletePromises);

        // Update the rows state by filtering out all successfully deleted rows
        setRows(prevRows => prevRows.filter(row =>
            !results.some(result => result.success && result.rowId === row.id)
        ));

        // You can also call handleBreadcrumbClick here if it's appropriate to do so
        handleBreadcrumbClick(current_path);
    };


    const handleFileUnzip = () => {
        // ---initialize unzip process start---
        const job_id = uuidv4();
        // Get the current row that needs to be unzipped
        const currentRow = rows.find(row => row.id === currentRowId);
        const zipFileName = currentRow.fileName;
        const storagePath = currentRow.path;
        const zipFileSize = currentRow.size;
        let newRows = [...rows];
        let uniqueFileName = zipFileName.replace(".zip", "")

        // Check if estimated size exceeds available storage space
        if (zipFileSize * 1.5 > storageSpace - storageSpaceUsed) {
            showErrorDialog('Error: Unzipping this file exceeds available storage space.');
            return;
        }

        if (newRows.some(row => row.fileName === uniqueFileName && row.type === "Folder")) {
            showErrorDialog("Folder with the same name is already existed in the selected directory");
            return;
        }

        setIsUnzipping(true);
        setUnzippingFileId(currentRowId);
        setSnackbarMessage(`Unzipping ${zipFileName}`);
        setSnackbarOpen(true);
        // ---initialize unzip process end---

        // ---send unzip request start---
        axios.post(
            '/api/unzip/',
            {
                parameters: {
                    user_id: userId,
                    zipFileName: zipFileName,
                    storagePath: storagePath,
                    start_datetime: new Date()
                },
                request_name: 'unzip_file',
                job_id: job_id
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            })
            // ---send unzip request end---
            // ---handle unzip response start---
            .then(response => {
                const newRow = {
                    id: newRows.length + 1,
                    type: 'Folder',
                    fileName: uniqueFileName,
                    creationDate: new Date(),
                    size: '-',
                    path: current_path,
                };
                newRows.push(newRow);
                setRows(newRows);
                setIsUnzipping(false);
                setUnzippingFileId(null);
                handleBreadcrumbClick(current_path);
                setSnackbarOpen(false);
            })
            .catch(error => {
                console.error("Error unzipping:", error);
                setIsUnzipping(false);
                setUnzippingFileId(null);
            });
        // ---handle unzip response end---
    };


    const handleDoubleClick = (params) => {
        // Prevent go into folders when the dialog shows
        if (deleteDialogOpen || renameDialogOpen || openNewDirectoryDialog || moveFileDialogOpen || showErrorSnackbar) {
            return;
        }

        if (params.row.type === 'Folder' || params.row.type === 'Downloads' || params.row.type === 'Demo') {
            setSelectedRows([]);
            const encrypted_id = params.row.encrypted_id;

            axios.defaults.headers.post['X-CSRFToken'] = csrfToken;
            const job_id = uuidv4();
            axios.post(
                '/api/storage/${encrypted_id}/',
                {
                    parameters: {
                        folder_name: params.row.fileName, encrypted_id: encrypted_id, current_path: current_path
                    },
                    request_name: 'enter_folder', // action name
                    job_id: job_id					// Unique job identifier
                },
                {
                    headers: {'X-CSRFToken': csrfToken},
                    withCredentials: true
                }).then(response => {
                if (response.data.status === "success") {
                    // update the rows state with the new folder content
                    const data_info = response.data.response_data;
                    // Construct your data from the response
                    const userData = {
                        page_mode: pageMode,
                        user_name: user_name,
                        user_id: userId,
                        storage_key: storage_key,
                        storage_space: storageSpace,
                        storageData: data_info.storage_json,
                        current_path: currentPath + params.row.fileName + '/',
                        current_fold_name: params.row.fileName,
                        sessionId_back: sessionId_back,
                        callback_url: callback_url,
                    };

                    // Navigate to CloudStorage with the unique URL
                    navigate(`/storage/${encrypted_id}`, {state: userData});
                } else {
                    console.error("Error enter:\n", response.data.response_data.error_message);
                }
            })
                .catch(error => {
                    console.error("Error fetching folder content:", error);
                });
        }
    };

    const handleRowSelection = (row) => {
        setActionTaken(false);
        // If the row is already selected, remove it from the selection
        if (selectedRows.includes(row.id)) {
            setSelectedRows(selectedRows.filter(id => id !== row.id));
        } else {
            const selectedRow = rows.find(r => r.id === row.id);
            if (selectedRow.type === 'Downloads' || selectedRow.path === demoRootPath) {  // Make Downloads folder not selectable
                return
            }
            setSelectedRows([...selectedRows, row.id]);
        }
    };


    const handleColumnHeaderClick = (params) => {
        setActionTaken(false);
        // If the checkbox column header is clicked
        if (params.field === '__check__') {
            // If there are any selected rows, deselect all. Otherwise, select all.
            if (selectedRows.length > 0) {
                setSelectedRows([]);
            } else {
                // Filter out rows with type 'Downloads', then map to get the ids of the remaining rows
                setSelectedRows(rows.filter(row => row.type !== 'Downloads' && row.path !== demoRootPath).map(row => row.id));
            }
        }
    };


    const handleProjectRoot = () => {
        axios.defaults.headers.post['X-CSRFToken'] = csrfToken;
        const job_id = uuidv4();
        const encrypted_id = 0;
        axios.post(
            '/api/project/${storage_key}/',
            {
                parameters: {
                    folder_name: 'project', encrypted_id: storage_key
                },
                request_name: 'enter_project', // action name
                job_id: job_id					// Unique job identifier
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }).then(response => {
            if (response.data.status === "success") {
                // update the rows state with the new folder content
                const data_info = response.data.response_data;
                // Construct your data from the response
                const userData = {
                    page_mode: pageMode,
                    user_name: user_name,
                    user_id: userId,
                    storage_key: storage_key,
                    storage_space: storageSpace,
                    storageData: data_info.storage_json,
                    current_path: storage_key + '/' + 'projects' + '/',
                    current_fold_name: 'projects',
                    sessionId_back: sessionId_back,
                    callback_url: callback_url,
                };

                // Navigate to project page with the unique URL
                navigate(`/project-manager/${storage_key}`, {state: userData});
            } else {
                console.error("Error enter:\n", response.data.response_data.error_message);
            }
        })
            .catch(error => {
                console.error("Error fetching folder content:", error);
            });
    }


    const handleShareRoot = () => {
        axios.defaults.headers.post['X-CSRFToken'] = csrfToken;
        const job_id = uuidv4();
        const encrypted_id = 0;
        axios.post(
            '/api/share/${storage_key}/',
            {
                parameters: {
                    folder_name: 'project', encrypted_id: storage_key
                },
                request_name: 'enter_share_with_me', // action name
                job_id: job_id					// Unique job identifier
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }).then(response => {
            if (response.data.status === "success") {
                // update the rows state with the new folder content
                const data_info = response.data.response_data;
                // Construct your data from the response
                const userData = {
                    page_mode: pageMode,
                    user_name: user_name,
                    user_id: userId,
                    storage_key: storage_key,
                    storage_space: storageSpace,
                    storageData: data_info.storage_json,
                    current_path: storage_key + '/' + 'projects' + '/',
                    current_fold_name: 'projects',
                    sessionId_back: sessionId_back,
                    callback_url: callback_url,
                };

                // Navigate to project page with the unique URL
                navigate(`/share-with-me/${storage_key}`, {state: userData});// Navigate to CloudStorage with the unique URL
            } else {
                console.error("Error enter:\n", response.data.response_data.error_message);
            }
        })
            .catch(error => {
                console.error("Error fetching folder content:", error);
            });
    }


    const handleStorageRoot = () => {

        axios.defaults.headers.post['X-CSRFToken'] = csrfToken;
        const job_id = uuidv4();
        axios.post(
            '/api/storage/${encrypted_id}/',
            {
                parameters: {
                    folder_name: 'useruploads',
                    encrypted_id: storage_key,
                    current_path: 'space_stage_version/' + storage_key + '/'
                },
                request_name: 'enter_folder', // action name
                job_id: job_id					// Unique job identifier
            },
            {
                headers: {'X-CSRFToken': csrfToken},
                withCredentials: true
            }).then(response => {
            if (response.data.status === "success") {
                // update the rows state with the new folder content
                const data_info = response.data.response_data;
                // Construct your data from the response
                const userData = {
                    page_mode: pageMode,
                    user_name: user_name,
                    user_id: userId,
                    storage_key: storage_key,
                    storage_space: storageSpace,
                    storageData: data_info.storage_json,
                    current_path: 'space_stage_version/' + storage_key + '/' + 'useruploads' + '/',
                    current_fold_name: 'useruploads',
                    sessionId_back: sessionId_back,
                    callback_url: callback_url,
                };

                // Navigate to CloudStorage with the unique URL
                navigate(`/storage/${storage_key}`, {state: userData});
            } else {
                console.error("Error enter:\n", response.data.response_data.error_message);
            }
        })
            .catch(error => {
                console.error("Error fetching folder content:", error);
            });
    }

    const handleJobRoot = () => {

        axios.defaults.headers.post['X-CSRFToken'] = csrfToken;
        const job_id = uuidv4();
        const userData = {
            page_mode: pageMode,
            user_name: user_name,
            user_id: userId,
            storage_key: storage_key,
            storage_space: storageSpace,
            current_path: 'space_stage_version/' + storage_key + '/' + 'useruploads' + '/',
            current_fold_name: 'useruploads',
            sessionId_back: sessionId_back,
            callback_url: callback_url,
        };
        navigate(`/job-manager/${storage_key}`, {state: userData});
    }


    const handleFileSelectionConfirm = (selectedRowIds) => {
        let DataList = [];
        selectedRowIds.forEach(rowId => {
            const fileToSubmit = rows.find(row => row.id === rowId);
            if (!fileToSubmit) return;  // Skip if the file is not found
            if (fileToSubmit.path.startsWith('space_stage_version/' + storage_key + '/useruploads/Demo Data/')) {
                fileToSubmit.path = fileToSubmit.path.replace(storage_key, '5MDSLse9kgHEewgu');
            }
            DataList.push([fileToSubmit.path, fileToSubmit.fileName]);
        })
        const dataToSend = {
            result: DataList,
            sessionId: sessionId_back,
            callback_url: callback_url,
            storage_key: storage_key,
            current_path_record: current_path
        };
        const originalOpener = window.myGlobalOpener;
        if (originalOpener && !originalOpener.closed) {
            originalOpener.postMessage(dataToSend, callback_url);
            window.close();
        } else {
            console.error('The opening window is no longer available.');
        }


    };


    const handleFileSelectionCancel = (e) => {

    };


    return (
        <div className="page-container">
            <Snackbar
                variant="soft"
                color="warning"
                open={showErrorSnackbar}
                onClose={() => setShowErrorSnackbar(false)}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                startDecorator={<WarningAmberOutlinedIcon/>}
                endDecorator={
                    <Button
                        onClick={() => setShowErrorSnackbar(false)}
                        size="sm"
                        variant="soft"
                        color="warning"
                    >
                        Dismiss
                    </Button>
                }
            >
                {errorMessage}
            </Snackbar>
            <h1>Welcome, {username}!</h1>
            <div className="flex-container">
                {(pageMode !== "file_selection") && (
                    <div className="left-nav">
                        <Autocomplete
                            freeSolo
                            id="search-file"
                            disableClearable
                            options={
                                completeStorageData
                                    .filter(option => option.Path.startsWith("space_stage_version/" + storage_key + "/useruploads/"))
                            }
                            getOptionLabel={(option) => option.FileName}
                            onChange={(event, value) => {
                                if (value && value.Path) {
                                    handleBreadcrumbClick(value.parentPath);
                                }
                            }}
                            renderOption={(props, option) => (
                                <li {...props} key={option.id}>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <img
                                            src={
                                                option.FileName && option.FileName.endsWith('.zip') ? zipIcon :
                                                    option.FileName.endsWith('.csv') ? csvIcon :
                                                        option.FileName.endsWith('.json') ? jsonIcon :
                                                            option.Type === 'File' ? fileIcon :
                                                                option.Type === 'Downloads' ? downloadFolderIcon :
                                                                    option.Type === 'Demo' ? demoFolderIcon :
                                                                        folderIcon
                                            }
                                            alt=''
                                            style={{marginRight: 8, width: 24, height: 24}}
                                        />
                                        <div>
                                            {option.FileName}
                                            <br/>
                                            <span style={{
                                                fontSize: '0.8em',
                                                color: '#888'
                                            }}>{option.Path.replace("space_stage_version/" + storage_key + "/useruploads/", "My Storage/")}</span>
                                        </div>
                                    </div>
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Search the Storage"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                />
                            )}
                        />
                        <Box mt={2}>
                            <ButtonGroup
                                variant="contained"
                                color="primary"
                                orientation="vertical"
                                sx={{
                                    fontSize: "larger", // or a specific size like "20px"
                                    '& .MuiButton-root': {
                                        padding: "12px 24px",  // Adjust padding as needed
                                    }
                                }}
                            >
                                <Button
                                    onClick={() => {
                                        setSelectedButton('my storage')
                                        handleStorageRoot()
                                    }}
                                    variant={selectedButton === 'my storage' ? 'solid' : 'outlined'}
                                >
                                    My Storage
                                </Button>
                                {(pageMode !== 'file_management') && (
                                    <Button
                                        onClick={() => {
                                            setSelectedButton('my project')
                                            handleProjectRoot()
                                            // navigate('/project-manager')
                                        }}
                                        variant={selectedButton === 'my project' ? 'solid' : 'outlined'}
                                    >
                                        My Projects
                                    </Button>
                                )}
                                {(pageMode !== 'file_management') && (
                                    <Button
                                        onClick={() => {
                                            setSelectedButton('share with me')
                                            handleShareRoot()
                                            // navigate('/share-with-me')
                                        }}
                                        variant={selectedButton === 'share with me' ? 'solid' : 'outlined'}
                                    >
                                        Shared with Me
                                    </Button>
                                )}
                            </ButtonGroup>
                        </Box>
                        <Box mt={6} position="relative">
                            <Typography variant="caption" level="title-sm">
                                Space Used {formatBytes(storageSpaceUsed) + '/' + formatBytes(storageSpace)}
                            </Typography>
                            <LinearProgress sx={{width: '100%', color: getProgressBarColor()}}
                                            color="primary"
                                            thickness={10}
                                            determinate
                                            size="lg"
                                            value={Math.min(Number((storageSpaceUsed / storageSpace) * 100), 100)}
                                            variant="soft"
                            />
                        </Box>
                    </div>
                )}
                <div className="main-content">
                    {
                        (pageMode === "development_mode" || pageMode === "full_storage" || pageMode === "file_management") && (
                            <Box display="flex" flexDirection="row" justifyContent="flex-start" mb={2} gap={2}>
                                <Button variant="outlined" startDecorator={<CreateNewFolderOutlinedIcon/>}
                                        onClick={handleNewDirectory} className="custom-button"
                                        disabled={currentPath.startsWith(demoRootPath) || storage_key === '5MDSLse9kgHEewgu'}>
                                    New Directory
                                </Button>
                                <Modal
                                    open={openNewDirectoryDialog}
                                    onClose={() => setOpenNewDirectoryDialog(false)}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <ModalDialog>
                                        <ModalClose/>
                                        <Typography level="h4">Create New Directory</Typography>
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            id="name"
                                            label="Directory Name"
                                            type="text"
                                            fullWidth
                                            value={newDirectoryName}
                                            onChange={handleDirectoryNameChange}
                                            onClose={() => setNewDirectoryName("")}
                                        />
                                        <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: 2, marginTop: 2}}>
                                            <Button
                                                color="primary"
                                                variant="outlined"
                                                onClick={() => {
                                                    setOpenNewDirectoryDialog(false);
                                                    setNewDirectoryName('');
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button onClick={addNewDirectory} color="primary" variant="outlined"
                                                    loading={isCreatingFolder} loadingPosition="start">
                                                Create
                                            </Button>
                                        </Box>
                                    </ModalDialog>
                                </Modal>
                                <input
                                    type="file"
                                    onChange={handleFileUpload}
                                    id="file-upload"
                                    multiple
                                    style={{display: 'none'}}
                                    disabled={currentPath.startsWith(demoRootPath) || storage_key === '5MDSLse9kgHEewgu'}
                                />
                                <label htmlFor="file-upload">
                                    <Button startDecorator={<UploadFileOutlinedIcon/>} variant="outlined" component="span"
                                            disabled={currentPath.startsWith(demoRootPath) || storage_key === '5MDSLse9kgHEewgu'}>
                                        Upload Files
                                    </Button>
                                </label>
                                <input
                                    type="file"
                                    directory=""
                                    webkitdirectory=""
                                    onChange={handleDirectoryUpload}
                                    id="directory-upload"
                                    style={{display: 'none'}}
                                    disabled={currentPath.startsWith(demoRootPath) || storage_key === '5MDSLse9kgHEewgu'}
                                />
                                <label htmlFor="directory-upload">
                                    <Button variant="outlined"
                                            startDecorator={<DriveFolderUploadOutlinedIcon/>}
                                            component="span"
                                            disabled={currentPath.startsWith(demoRootPath) || storage_key === '5MDSLse9kgHEewgu'}
                                    >
                                        Upload Directory
                                    </Button>
                                </label>
                                <Button variant="outlined"
                                        startDecorator={<CloudDownloadOutlinedIcon/>}
                                        disabled={selectedRows.length === 0}
                                        onClick={() => handleFileDownload('button', selectedRows)}>
                                    Download
                                </Button>
                                <Button
                                    variant="outlined"
                                    startDecorator={<DeleteOutlineOutlinedIcon/>}
                                    disabled={selectedRows.length === 0 || currentPath.startsWith(demoRootPath)}
                                    onClick={() => {
                                        setDeleteSource('button');
                                        setDeleteDialogOpen(true);
                                    }}
                                >
                                    Delete
                                </Button>
                            </Box>
                        )
                    }
                    <div style={{margin: '10px 0'}} className="current-path">
                        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small"/>}>
                            {breadcrumbLinks}
                        </Breadcrumbs>
                    </div>
                    <div style={{width: '100%'}}>
                        <DataGrid
                            sx={{  // remove the outline on focus in datagrid
                                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                    outline: "none !important",
                                },
                            }}
                            rows={rows}
                            columns={pageMode === "file_selection" ? columns.slice(0, -1) : columns}
                            checkboxSelection
                            rowSelectionModel={selectedRows}
                            onCellClick={handleRowSelection}
                            isRowSelectable={(params) => params.row.type !== 'Downloads' && params.row.path !== demoRootPath}
                            onColumnHeaderClick={handleColumnHeaderClick}
                            onCellDoubleClick={handleDoubleClick}
                            // initialState={{
                            //     pagination: {
                            //         paginationModel: {
                            //             pageSize: 10,
                            //         },
                            //     },
                            // }}
                        />
                    </div>
                    <br></br>
                    {(pageMode === "development_mode" || pageMode === "file_selection") && (selectedRows.length > 0) && (
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-start"
                            mb={2}
                            gap={2}
                            sx={{
                                position: 'fixed', // This makes the box position itself relative to the viewport
                                bottom: 5, // Distance from the bottom of the viewport
                                right: 20, // Distance from the right of the viewport
                                zIndex: 1000, // Ensure it sits above other content
                            }}
                        >
                            <Button onClick={() => handleFileSelectionConfirm(selectedRows)}
                                    className="custom-button">
                                Confirm
                            </Button>
                        </Box>
                    )}
                </div>
                <Snackbar open={snackbarOpen}
                          autoHideDuration={null}
                          onClose={(event, reason) => {
                              if (reason === 'clickaway') {
                                  return;
                              }
                          }}
                >
                    {snackbarMessage}
                </Snackbar>
            </div>
        </div>
    );

};
export default CloudStorage;
