import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const containerStyle = {
  backgroundColor: '#e6f2ff',
  minHeight: '100vh',
  padding: '20px'
};

const cardStyle = {
  boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
  borderRadius: '0.5rem',
  border: 'none',
  backgroundColor: '#f9f9f9',
};

const cardBodyStyle = {
  backgroundColor: '#f9fafd',
  borderRadius: '0.5rem'
};

const legendStyle = {
  fontSize: '18px',
  fontWeight: '600',
  color: '#333'
};

const labelStyle = {
  fontSize: '14px',
  color: '#333',
  fontWeight: '500',
  marginBottom: '8px'
};

const selectStyle = {
  width: '100%',
  padding: '6px',
  borderRadius: '4px',
  border: '1px solid #ccc',
  fontSize: '14px'
};

const checkboxLabelStyle = {
  fontSize: '14px',
  marginLeft: '5px',
  color: '#333'
};

const buttonContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  marginTop: '20px'
};

const buttonStyle = {
  backgroundColor: '#0080ff',
  border: 'none',
  color: '#fff',
  padding: '8px 20px',
  fontSize: '16px',
  cursor: 'pointer',
  borderRadius: '4px',
};

const buttonSpacing = {
  marginLeft: '20px'
};

export default function CRDCAnalysisTool() {
  const [program, setProgram] = useState('TCGA');

  const [primarySiteTCGA, setPrimarySiteTCGA] = useState('liver and intrahepatic bile ducts');
  const [diseaseTypeTCGA, setDiseaseTypeTCGA] = useState('adenomas and adenocarcinomas');

  const [primarySiteCPTAC, setPrimarySiteCPTAC] = useState('breast');
  const [diseaseTypeCPTAC, setDiseaseTypeCPTAC] = useState('adenocarcinoma');

  const [primarySiteTCIA, setPrimarySiteTCIA] = useState('breast');
  const [diseaseTypeTCIA, setDiseaseTypeTCIA] = useState('cystic, mucinous and serous neoplasms');

  const [afterRetrieve, setAfterRetrieve] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');

  const [dataTypes, setDataTypes] = useState({
    mRNASeq: false,
    miRSeq: true,
    proteomics: false,
    acetylproteomics: false,
    transcriptomics: false,
    phosphoproteomics: false,
    tcia: false,
  });

  const [grouping, setGrouping] = useState('disease');
  const [groupingValue, setGroupingValue] = useState(60);
  const [retrievalFlag, setRetrievalFlag] = useState(0);

  useEffect(() => {
    const last_time = window.last_time;
    if (last_time !== undefined && last_time !== null && last_time !== -2) {
      const last_configure = window.last_configure;
      if (last_configure && last_configure.data_source_type === 'CRDC') {
        setProgram(last_configure.program_choice || 'TCGA');

        setProjectList([{ id: last_configure.project }]);
        setSelectedProject(last_configure.project);
        setAfterRetrieve(true);
        setRetrievalFlag(1);

        if (last_configure.data_type) {
          const chosen = JSON.parse(last_configure.data_type);
          setDataTypes((prev) => {
            const newData = {...prev};
            for (const key in newData) {
              newData[key] = chosen.includes(key);
            }
            return newData;
          });
        }

        setGrouping(last_configure.grouping || 'disease');
        setGroupingValue(last_configure.grouping_value || 60);
      }
    }
  }, []);

  function getCurrentPrimarySite() {
    if (program === 'TCGA') return primarySiteTCGA;
    if (program === 'CPTAC') return primarySiteCPTAC;
    if (program === 'TCIA') return primarySiteTCIA;
    return '';
  }

  function getCurrentDiseaseType() {
    if (program === 'TCGA') return diseaseTypeTCGA;
    if (program === 'CPTAC') return diseaseTypeCPTAC;
    if (program === 'TCIA') return diseaseTypeTCIA;
    return '';
  }

  function handleDataTypeChange(type) {
    setDataTypes({ ...dataTypes, [type]: !dataTypes[type] });
  }

  function onChangeSearchCondition(handler) {
    return (e) => {
      handler(e.target.value);
      setAfterRetrieve(false);
      setRetrievalFlag(0);
    };
  }

  function handleProgramChange(value) {
    setProgram(value);
    setAfterRetrieve(false);
    setRetrievalFlag(0);
  }

  function retrieve() {
    const csrftoken = window.csrfToken;

    let finalProgram = program;
    if (program === 'TCIA') {
      finalProgram = 'TCGA';
    }

    const primarySite = getCurrentPrimarySite();
    const diseaseType = getCurrentDiseaseType();

    fetch("/craft_api/ProjectRetrieve", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken
      },
      body: JSON.stringify({
        primary_site_choice: primarySite,
        program_choice: finalProgram,
        disease_type_choice: diseaseType
      })
    })
    .then(response => {
      if (!response.ok) throw new Error('Network response was not ok');
      return response.json();
    })
    .then(data => {
      const result = data.retrieval_result || [];
      setProjectList(result);
      setAfterRetrieve(true);
      setRetrievalFlag(1);
      if (result.length > 0) {
        setSelectedProject(result[0].id);
      }
    })
    .catch(error => console.error("Error:", error));
  }

  function backtoMetCraft() {
    const last_time = window.last_time;
    if (retrievalFlag === 0 && (last_time === -2 || last_time === undefined)) {
      alert('Please retrieve first.');
      return;
    }

    let finalProgram = program;
    if (program === 'TCIA') {
      finalProgram = 'TCGA';
    }

    const selectedDataTypes = Object.keys(dataTypes).filter(key => dataTypes[key]);

    let bdrvalue = 0;
    if (grouping === 'age' || grouping === 'survival day') {
      bdrvalue = groupingValue;
    }

    const param = {
      data_source_type: 'CRDC',
      program_choice: finalProgram,
      data_type: JSON.stringify(selectedDataTypes),
      project: selectedProject,
      grouping: grouping,
      grouping_value: bdrvalue
    };

    if (window.opener && window.opener.getDRParameter) {
      window.opener.getDRParameter(param);
    }
  }

  function renderDataTypes() {
    let finalProgram = program === 'TCIA' ? 'TCGA' : program;

    const showMiR = (finalProgram === 'TCGA');
    const showMRNA = (finalProgram === 'TCGA');
    const showProteomics = (finalProgram === 'CPTAC');
    const showAcetyl = (finalProgram === 'CPTAC');
    const showTranscript = (finalProgram === 'CPTAC');
    const showPhospho = (finalProgram === 'CPTAC');
    const showTcia = (finalProgram === 'TCGA' && (selectedProject === 'TCGA-OV' || selectedProject === 'TCGA-BRCA'));

    return (
      <div>
        {showMRNA && (
          <div style={{marginBottom:'5px'}}>
            <input
              type="checkbox"
              checked={dataTypes.mRNASeq}
              onChange={() => handleDataTypeChange('mRNASeq')}
            />
            <span style={checkboxLabelStyle}>mRNASeq</span>
          </div>
        )}
        {showMiR && (
          <div style={{marginBottom:'5px'}}>
            <input
              type="checkbox"
              checked={dataTypes.miRSeq}
              onChange={() => handleDataTypeChange('miRSeq')}
            />
            <span style={checkboxLabelStyle}>miRSeq</span>
          </div>
        )}
        {showProteomics && (
          <div style={{marginBottom:'5px'}}>
            <input
              type="checkbox"
              checked={dataTypes.proteomics}
              onChange={() => handleDataTypeChange('proteomics')}
            />
            <span style={checkboxLabelStyle}>proteomics</span>
          </div>
        )}
        {showAcetyl && (
          <div style={{marginBottom:'5px'}}>
            <input
              type="checkbox"
              checked={dataTypes.acetylproteomics}
              onChange={() => handleDataTypeChange('acetylproteomics')}
            />
            <span style={checkboxLabelStyle}>acetylproteomics</span>
          </div>
        )}
        {showTranscript && (
          <div style={{marginBottom:'5px'}}>
            <input
              type="checkbox"
              checked={dataTypes.transcriptomics}
              onChange={() => handleDataTypeChange('transcriptomics')}
            />
            <span style={checkboxLabelStyle}>transcriptomics</span>
          </div>
        )}
        {showPhospho && (
          <div style={{marginBottom:'5px'}}>
            <input
              type="checkbox"
              checked={dataTypes.phosphoproteomics}
              onChange={() => handleDataTypeChange('phosphoproteomics')}
            />
            <span style={checkboxLabelStyle}>phosphoproteomics</span>
          </div>
        )}
        {showTcia && (
          <div style={{marginBottom:'5px'}}>
            <input
              type="checkbox"
              checked={dataTypes.tcia}
              onChange={() => handleDataTypeChange('tcia')}
            />
            <span style={checkboxLabelStyle}>TCIA-{selectedProject}</span>
          </div>
        )}
      </div>
    );
  }

  function renderSlider() {
    let minVal = 0;
    let maxVal = 100;
    if (grouping === 'age') {
      minVal = 30;
      maxVal = 100;
    } else if (grouping === 'survival day') {
      minVal = 0;
      maxVal = 5000;
    } else {
      return null;
    }

    return (
      <div style={{marginTop:'10px'}}>
        <div style={{display:'flex', justifyContent:'space-between', fontSize:'14px', color:'#333'}}>
          <span>{minVal}</span>
          <span>{maxVal}</span>
        </div>
        <input
          type="range"
          min={minVal}
          max={maxVal}
          value={groupingValue}
          onChange={(e) => setGroupingValue(parseInt(e.target.value, 10))}
          style={{width:'100%', marginTop:'5px'}}
        />
        <div style={{textAlign:'center', marginTop:'5px', fontSize:'14px', color:'#333'}}>{groupingValue}</div>
      </div>
    );
  }

  return (
    <div style={containerStyle}>
      <div className="row">
        <div className="col-lg-12">
          <div className="card" style={cardStyle}>
            <div className="card-body" style={cardBodyStyle}>
              <fieldset>
                <legend style={legendStyle}>
                  CRDC
                  <sup title="Default value is recommended." style={{marginLeft:'5px', color:'#666'}}>
                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                  </sup>
                </legend>
                <div style={labelStyle}>Program:</div>
                <select
                  id="program_choice"
                  value={program}
                  onChange={(e)=>{handleProgramChange(e.target.value)}}
                  style={selectStyle}
                >
                  <option value="TCGA">TCGA</option>
                  <option value="CPTAC">CPTAC</option>
                  <option value="TCIA">TCIA</option>
                </select>
                <br/><br/>
                {program === 'TCGA' && (
                  <>
                    <div style={labelStyle}>Primary Site:</div>
                    <select
                      id="TCGA_primary_site_choice"
                      value={primarySiteTCGA}
                      onChange={onChangeSearchCondition(setPrimarySiteTCGA)}
                      style={selectStyle}
                    >
                      <option>colon</option>
                      <option>connective, subcutaneous and other soft tissues</option>
                      <option>heart, mediastinum, and pleura</option>
                      <option>kidney</option>
                      <option>stomach</option>
                      <option>brain</option>
                      <option>uterus bronchus and lung</option>
                      <option>retroperitoneum and peritoneum</option>
                      <option>bones, joints and articular cartilage of other and unspecified sites</option>
                      <option>breast</option>
                      <option>corpus uteri</option>
                      <option>hematopoietic and reticuloendothelial systems</option>
                      <option>liver and intrahepatic bile ducts</option>
                      <option>ovary</option>
                    </select>
                    <br/><br/>
                    <div style={labelStyle}>Disease Type:</div>
                    <select
                      id="TCGA_disease_type_choice"
                      value={diseaseTypeTCGA}
                      onChange={onChangeSearchCondition(setDiseaseTypeTCGA)}
                      style={selectStyle}
                    >
                      <option>adenomas and adenocarcinomas</option>
                      <option>cystic, mucinous and serous neoplasms</option>
                      <option>epithelial neoplasms, nos</option>
                      <option>squamous cell neoplasms</option>
                      <option>complex epithelial neoplasms</option>
                      <option>ductal and lobular neoplasms</option>
                      <option disabled>gliomas</option>
                      <option disabled>nevi and melanomas</option>
                      <option>not reported</option>
                    </select>
                  </>
                )}
                {program === 'CPTAC' && (
                  <>
                    <div style={labelStyle}>Primary Site:</div>
                    <select
                      id="CPTAC_primary_site_choice"
                      value={primarySiteCPTAC}
                      onChange={onChangeSearchCondition(setPrimarySiteCPTAC)}
                      style={selectStyle}
                    >
                      <option>breast</option>
                      <option>kidney</option>
                      <option>colon</option>
                      <option>uterus</option>
                      <option>brain</option>
                      <option>head-neck</option>
                      <option>lung</option>
                      <option>ovary</option>
                    </select>
                    <br/><br/>
                    <div style={labelStyle}>Disease Type:</div>
                    <select
                      id="CPTAC_disease_type_choice"
                      value={diseaseTypeCPTAC}
                      onChange={onChangeSearchCondition(setDiseaseTypeCPTAC)}
                      style={selectStyle}
                    >
                      <option>adenocarcinoma</option>
                      <option>cell carcinoma</option>
                      <option>corpus endometrialcarcinoma</option>
                      <option>ovarian serous cystadenocarcinoma</option>
                      <option>breast invasive carcinoma</option>
                      <option>glioblastoma</option>
                      <option>other</option>
                    </select>
                  </>
                )}
                {program === 'TCIA' && (
                  <>
                    <div style={labelStyle}>Primary Site:</div>
                    <select
                      id="TCIA_primary_site_choice"
                      value={primarySiteTCIA}
                      onChange={onChangeSearchCondition(setPrimarySiteTCIA)}
                      style={selectStyle}
                    >
                      <option>breast</option>
                      <option>ovary</option>
                    </select>
                    <br/><br/>
                    <div style={labelStyle}>Disease Type:</div>
                    <select
                      id="TCIA_disease_type_choice"
                      value={diseaseTypeTCIA}
                      onChange={onChangeSearchCondition(setDiseaseTypeTCIA)}
                      style={selectStyle}
                    >
                      <option>adenomas and adenocarcinomas</option>
                      <option>cystic, mucinous and serous neoplasms</option>
                      <option>not reported</option>
                    </select>
                  </>
                )}
              </fieldset>
            </div>
          </div>
        </div>
      </div>
      <br/><br/>
      {afterRetrieve && (
        <div id="after_retrieve">
          <div className="row" style={{display:'flex', justifyContent:'space-between', alignItems:'flex-start'}}>
            <div className="col-md-4">
              <div className="card" style={cardStyle}>
                <div className="card-body" style={cardBodyStyle}>
                  <fieldset>
                    <legend style={legendStyle}><font size="3">Select the project:</font></legend>
                    <select
                      id="project_choice"
                      value={selectedProject}
                      onChange={e=>setSelectedProject(e.target.value)}
                      style={selectStyle}
                    >
                      {projectList.map(p => (
                        <option key={p.id} value={p.id}>{p.id}</option>
                      ))}
                    </select>
                  </fieldset>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card" style={cardStyle}>
                <div className="card-body" style={cardBodyStyle}>
                  <fieldset>
                    <legend style={legendStyle}>Select the omics data type:</legend>
                    {renderDataTypes()}
                  </fieldset>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card" style={cardStyle}>
                <div className="card-body" style={cardBodyStyle}>
                  <fieldset>
                    <legend style={legendStyle}>Select the images data:</legend>
                    <div style={{fontSize:'14px', color:'#666'}}>
                      If available, displayed in omics data type section.
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
          <br/>
          <div className="row" style={{marginTop:'20px'}}>
            <div className="col-md-6">
              <div className="card" style={cardStyle}>
                <div className="card-body" style={cardBodyStyle}>
                  <fieldset>
                    <legend style={legendStyle}>Select the grouping feature:</legend>
                    <select
                      id="grouping_choice"
                      value={grouping}
                      onChange={e=>setGrouping(e.target.value)}
                      style={selectStyle}
                    >
                      <option value="disease">disease</option>
                      <option value="gender">gender</option>
                      <option value="age">age</option>
                      <option value="survival day">survival day</option>
                    </select>
                    {(grouping === 'age' || grouping === 'survival day') && (
                      <div id="plot">
                        {renderSlider()}
                      </div>
                    )}
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div style={buttonContainerStyle}>
        <button
          type="button"
          id='submit'
          style={buttonStyle}
          onClick={retrieve}
        >
          Retrieve
        </button>
        <button
          type="button"
          id='submit2'
          style={{...buttonStyle, ...buttonSpacing}}
          onClick={backtoMetCraft}
        >
          Submit
        </button>
      </div>
    </div>
  );
}